import {BrowserRouter as Router, Routes, Route } from "react-router-dom"
import './App.css';
import Home from "./pages/home";
import SearchVIP from "./pages/searchVIP";
import NewVIP from "./pages/newVIP";
import SearchReceipts from "./pages/searchReceipts";
import SearchShippings from "./pages/searchShippings";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/newVIP" element={<NewVIP />} />
        <Route path="/searchVIP" element={<SearchVIP />} />
        <Route path="/searchReceipts" element={<SearchReceipts />} />
        <Route path="/searchShippings" element={<SearchShippings />} />
      </Routes>
    </Router>
  );
}

export default App;
