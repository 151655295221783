import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavBar from '../components/navbar/navbar';

function Home() {
    const { t } = useTranslation();

    return (
        <div>
            <NavBar />
            <div className="flex flex-col items-center mt-32">
                <h1 className="text-2xl font-bold mb-8">{t('home_title')}</h1>
                <div className="space-y-4">
                    <Link to="/newVIP" className="block w-64 px-4 py-2 text-center text-white bg-blue-500 rounded hover:bg-blue-700">
                        {t('home_new_customer')}
                    </Link>
                    <Link to="/searchVIP" className="block w-64 px-4 py-2 text-center text-white bg-blue-500 rounded hover:bg-blue-700">
                        {t('home_search_vip')}
                    </Link>
                    <Link to="/searchReceipts" className="block w-64 px-4 py-2 text-center text-white bg-blue-500 rounded hover:bg-blue-700">
                        {t('home_search_receipts')}
                    </Link>
                    <Link to="/searchShippings" className="block w-64 px-4 py-2 text-center text-white bg-blue-500 rounded hover:bg-blue-700">
                        {t('home_search_shippings')}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Home;
