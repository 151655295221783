// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      "home_title": "PLshipping",
      "home_new_customer": "New Customer VIP Number",
      "home_search_vip": "Search VIP Number",
      "home_search_receipts": "Search Receipts",
      "home_search_shippings": "Search Shippings",
      "new_vip_title": "Apply for new VIP #",
      "new_vip_name": "Name",
      "new_vip_email": "Email",
      "new_vip_phone": "Phone",
      "new_vip_pickup_location": "Pickup Location",
      "new_vip_submit": "Submit",
      "search_vip_title": "Search VIP Number",
      "search_vip_subtitle": "Please enter your email or phone",
      "search_vip_email": "Email (optional)",
      "search_vip_phone": "Phone (optional)",
      "search_vip_pickup_location": "Pickup Location",
      "search_vip_cta": "Search",
      "vip_modal_title": "Your VIP Number",
      "vip_modal_shipping_address": "Shipping Address (Copy & Paste)",
      "vip_modal_recipient": "Name: ",
      "vip_modal_tel_phone": "Tel: ",
      "vip_modal_postcode": "Postcode: ",
      "vip_modal_no_search_result": "VIP Number Not Found",
      "vip_modal_copy_button":"Copy",
      "vip_modal_copy_button_copied":"Copied",
      "loc_lasalle": "Lasalle (Montreal)",
      "loc_ile_des_soeurs": "Île des Sœurs (Montreal)",
      "loc_rosement": "Rosement (Montreal)",
      "loc_oakville": "Oakville (Toronto)",
      "loc_vaughan": "Vaughan (Toronto)",
      "loc_ottawa": "Ottawa",
      "loc_kanata": "Kanata (Ottawa)",
      "loc_gatineau": "Gatineau (Ottawa)",
      "receipts_title": "Search Receipts",
      "receipts_subtitle": "Your VIP Number",
      "receipts_search": "Search",
      "receipts_searching": "Searching...",
      "receipts_no_data": "No Data",
      "receipts_header_barcode": "Barcode",
      "receipts_header_recipient": "Recipient",
      "receipts_header_date": "Date",
      "shippings_title": "Search Shippings",
      "shippings_subtitle": "Your VIP Number",
      "shippings_alert": "Red items need customs declaration materials\nPlease contact us on WeChat: Sevice-PLshipping",
      "shippings_search": "Search",
      "shippings_searching": "Searching...",
      "shippings_no_data": "No Data",
      "shippings_header_tracking_number": "Tracking Number",
      "shippings_header_carrier": "Carrier",
      "shippings_header_equipment_number": "Equipment Number",
      "shippings_header_eta": "ETA",
    }
  },
  zh: {
    translation: {
      "home_title": "淜涞海运",
      "home_new_customer": "新客户VIP申请",
      "home_search_vip": "查询VIP号码",
      "home_search_receipts": "查询签收单",
      "home_search_shippings": "查询装船包裹",
      "new_vip_title": "新客户VIP申请",
      "new_vip_name": "姓名",
      "new_vip_email": "邮箱",
      "new_vip_phone": "手机",
      "new_vip_pickup_location": "提货地点",
      "new_vip_submit": "提交",
      "search_vip_title": "查询VIP号码",
      "search_vip_subtitle": "请输入邮箱或手机",
      "search_vip_email": "邮箱 (可选)",
      "search_vip_phone": "手机 (可选)",
      "search_vip_pickup_location": "提货地点",
      "search_vip_cta": "查询",
      "vip_modal_title": "您的VIP号码",
      "vip_modal_shipping_address": "国内仓库收件标准格式（请复制粘贴）",
      "vip_modal_recipient": "姓名：",
      "vip_modal_tel_phone": "电话：",
      "vip_modal_postcode": "邮编：",
      "vip_modal_no_search_result": "没有找到您的VIP号码",
      "vip_modal_copy_button":"复制",
      "vip_modal_copy_button_copied":"已复制",
      "loc_lasalle": "拉萨尔 (蒙特利尔)",
      "loc_ile_des_soeurs": "修女岛 (蒙特利尔)",
      "loc_rosement": "罗斯蒙特 (蒙特利尔)",
      "loc_oakville": "奥克维尔 (多伦多)",
      "loc_vaughan": "旺市 (多伦多)",
      "loc_ottawa": "渥太华",
      "loc_kanata": "卡娜塔 (渥太华)",
      "loc_gatineau": "加蒂诺 (渥太华)",
      "receipts_title": "查询签收单",
      "receipts_subtitle": "您的VIP号码",
      "receipts_search": "查询",
      "receipts_searching": "查询中...",
      "receipts_no_data": "无数据",
      "receipts_header_barcode": "快递单号",
      "receipts_header_recipient": "收件人",
      "receipts_header_date": "扫码时间",
      "shippings_title": "查询装船包裹",
      "shippings_subtitle": "您的VIP号码",
      "shippings_alert": "红色项目需要提供报关材料\n请联系微信客服：Sevice-PLshipping",
      "shippings_search": "查询",
      "shippings_searching": "查询中...",
      "shippings_no_data": "无数据",
      "shippings_header_tracking_number": "承运单号",
      "shippings_header_carrier": "承运人",
      "shippings_header_equipment_number": "航次",
      "shippings_header_eta": "预计到达时间",
    }
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
      caches: ['localStorage', 'cookie']
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
