import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  APIGetVIP
} from '../apis/api';
import Modal from '../components/modal/modal';
import NavBar from '../components/navbar/navbar';
import { Loc2Code } from './newVIP';

function SearchVIP() {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('L');

  const [isLoading, setIsLoading] = useState(true);
  const [vipNumber, setVipNumber] = useState('');
  const [recipient, setRecipient] = useState('');
  const [showModal, setShowModal] = useState(false);
  
  useEffect(() => {
    const handleLanguageChange = () => {
      const select = document.getElementById("location");
      if (select) {
        select.selectedIndex = 0;
      }
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let code = Loc2Code(location, t)
    setIsLoading(true)
    setShowModal(true)
    if (email.length === 0 && phone.length === 0) {
      setVipNumber(null)
      setIsLoading(false)
    } else {
      APIGetVIP(email, phone, code, (success, json) => {
        setVipNumber(json.vip || null)
        setRecipient(json.name || null)
        setIsLoading(false)
      })
    }
  };

  return (
    <div>
      <NavBar hasBack={true} />
      <div className="mt-16 flex justify-center">
        <div className="w-full max-w-md p-5 bg-white rounded-lg">
          <h1 className="text-xl font-semibold text-center mb-2">{t('search_vip_title')}</h1>
          <label className="block text-gray-700 text-sm text-center mb-6" htmlFor="email">
            {t('search_vip_subtitle')}
          </label>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                {t('search_vip_email')}
              </label>
              <input
                type="email"
                id="email"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                {t('search_vip_phone')}
              </label>
              <input
                type="tel"
                id="phone"
                defaultValue={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="location">
                {t('search_vip_pickup_location')}
              </label>
              <select
                id="location"
                onChange={(e) => setLocation(e.target.value)}
                className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline"
              >
                {/* <option>{t('loc_lasalle')}</option>
                <option>{t('loc_ile_des_soeurs')}</option>
                <option>{t('loc_rosement')}</option> */}
                <option>{t('loc_oakville')}</option>
                <option>{t('loc_vaughan')}</option>
                <option>{t('loc_kanata')}</option>
                <option>{t('loc_gatineau')}</option>
              </select>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {t('search_vip_cta')}
            </button>
          </form>
        </div>
        {showModal && <Modal isLoading={isLoading} vipNumber={vipNumber} recipient={recipient} onClose={() => setShowModal(false)} />}
      </div>
    </div>
  );
}

export default SearchVIP;
