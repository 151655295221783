export const HandleResponseJson = async (rsp) => {
    const text = await rsp.text()
    return text ? JSON.parse(text) : {}
}

export const HandleResponseError = (rsp) => {
    return rsp.status < 300
}

export async function APICreateVIP(name, email, phone, location, callback) {
    try {
        let url = `${process.env.REACT_APP_BACKEND_URL_VIP}`
        let rsp = await fetch(url, {
            method: 'post',
            body:JSON.stringify({
                name,
                email,
                phone,
                location
            })
        })
        const json = await HandleResponseJson(rsp)
        const isSuccess = HandleResponseError(rsp)
        callback(isSuccess, json)
    } catch( err ) {
        console.error("API ERROR: ", err)
        callback(false, err)
    }
}

export async function APIGetVIP(email, phone, location, callback) {
    try {
        let url = `${process.env.REACT_APP_BACKEND_URL_VIP}?`
        let needAnd = false
        if (email !== undefined) {
            url += `email=${encodeURIComponent(email)}`
            needAnd = true
        }
        if (phone !== undefined) {
            if (needAnd) { url += '&' }
            url += `phone=${encodeURIComponent(phone)}`
            needAnd = true
        }
        if (location !== undefined) {
            if (needAnd) { url += '&' }
            url += `location=${encodeURIComponent(location)}`
        }
        let rsp = await fetch(url, {
            method: 'get'
        })
        const json = await HandleResponseJson(rsp)
        const isSuccess = HandleResponseError(rsp)
        callback(isSuccess, json)
    } catch( err ) {
        console.error("API ERROR: ", err)
        callback(false, err)
    }
}

export async function APIGetReceipts(vip, callback) {
    try {
        let url = `${process.env.REACT_APP_BACKEND_URL_RECEIPT}?`
        if (vip !== undefined) {
            url += `vip=${encodeURIComponent(vip)}`
        }
        let rsp = await fetch(url, {
            method: 'get'
        })
        const json = await HandleResponseJson(rsp)
        const isSuccess = HandleResponseError(rsp)
        callback(isSuccess, json)
    } catch( err ) {
        console.error("API ERROR: ", err)
        callback(false, err)
    }
}

export async function APIGetShipping(vip, callback) {
    try {
        let url = `${process.env.REACT_APP_BACKEND_URL_SHIPPING}?`
        if (vip !== undefined) {
            url += `vip=${encodeURIComponent(vip)}`
        }
        let rsp = await fetch(url, {
            method: 'get'
        })
        const json = await HandleResponseJson(rsp)
        const isSuccess = HandleResponseError(rsp)
        callback(isSuccess, json)
    } catch( err ) {
        console.error("API ERROR: ", err)
        callback(false, err)
    }
}