import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIGetShipping } from '../apis/api';
import NavBar from '../components/navbar/navbar';

function SearchShippings() {
  const { t } = useTranslation();

  const [vip, setVip] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [receipts, setReceipts] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setReceipts([])
    if (vip.length > 0) {
      setIsLoading(true);
      APIGetShipping(vip, (success, list) => {
        setIsLoading(false);
        const sortedList = list//.sort((a, b) => new Date(b.date) - new Date(a.date));
        setReceipts(sortedList);
      });
    }
  };

  const ReceiptsTable = ({ receipts }) => {
    return (
      <div className="w-full overflow-x-auto px-4">
        <table className="w-full bg-white">
          <thead>
            <tr>
              <th className="p-1 text-xs font-semibold tracking-wide text-center text-gray-700 border-b border-gray-300">
                {t('shippings_header_tracking_number')}
              </th>
              <th className="p-1 text-xs font-semibold tracking-wide text-center text-gray-700 border-b border-gray-300">
                {t('shippings_header_carrier')}
              </th>
              <th className="p-1 text-xs font-semibold tracking-wide text-center text-gray-700 border-b border-gray-300">
                {t('shippings_header_equipment_number')}
              </th>
              <th className="p-1 text-xs font-semibold tracking-wide text-center text-gray-700 border-b border-gray-300">
                {t('shippings_header_eta')}
              </th>
            </tr>
          </thead>
          <tbody>
            {receipts.map((item, index) => {
              const date = new Date(item.eta)
              const shortDate = date.toLocaleDateString('en-CA');
              const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
              let trackingNo = String(item.trackingNo || "")
              const isRed = trackingNo.endsWith('*')
              trackingNo = trackingNo.endsWith('*') ? trackingNo.slice(0, -1) : trackingNo
              return (
                <tr key={index} className={`hover:bg-gray-100 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                  <td className={`p-1 text-xs text-center ${isRed ? 'text-red-500' : 'text-gray-700'}`}>
                    {trackingNo}
                  </td>
                  <td className="p-1 text-xs text-center text-gray-700">{item.carrier}</td>
                  <td className="p-1 text-xs text-center text-gray-700">{item.equipmentNo}</td>
                  <td className="p-1 text-xs text-center text-gray-700 block sm:hidden">{shortDate}</td>
                  <td className="p-1 text-xs text-center text-gray-700 hidden sm:block">{`${shortDate} ${time}`}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {(receipts?.length || 0) === 0 && !isLoading && (
          <h1 className="m-4 text-lg font-medium text-center text-gray-500">{t('shippings_no_data')}</h1>
        )}
      </div>
    );
  };

  return (
    <div>
      <NavBar hasBack={true} />
      <div className="mt-16 flex flex-col items-center justify-center w-full">
        <div className="w-full max-w-md p-5 bg-white rounded-lg">
          <h1 className="text-xl font-semibold text-center mb-2">{t('shippings_title')}</h1>
          <label className="block text-gray-700 text-sm text-center mb-6" htmlFor="email">
            {t('shippings_subtitle')}
          </label>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                type="text"
                id="vip"
                placeholder='VIP'
                value={vip}
                disabled={isLoading}
                onChange={(e) => setVip(e.target.value)}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-gray-200 disabled:text-gray-400"
              />
            </div>
            <button
              type="submit"
              className="flex justify-center items-center gap-1 relative w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:bg-blue-400"
              disabled={isLoading}
            >
              {t(isLoading ? 'shippings_searching' : 'shippings_search')}
            </button>
          </form>
        </div>
        {receipts.some(item => String(item.trackingNo || "").endsWith('*')) && (
          <label className="text-sm text-center text-red-500 mb-4 whitespace-pre-line">
            {t('shippings_alert')}
          </label>
        )}
        <ReceiptsTable receipts={receipts} />
        {isLoading && (
          <div className="mx-auto my-8">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchShippings;
